<template>
  <CHeader fixed with-subheader light>
    <CHeaderBrand class="padded" to="/">
      <CImg src="/img/assistant-text.png" width="140" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          {{ userName || usernameText }}
        </CHeaderNavLink>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import Pusher from 'pusher-js'
import axios from "axios";
import { AUTH_LOGOUT } from "../store/actions/auth";

export default {
  name: "TheHeader",
  data() {
    return {
      me: {},
      creditInUserCurrency: 0,
      userCreditText: "",
      usernameText: "",
      pusherClient: null,
      pusherChannelName: "",
    };
  },
  components: {
    TheHeaderDropdownAccnt,
  },
  computed: {
    userName() {
      if (this.$store.state.user.profile.data != undefined) {
        return (
          "User: " +
          this.$store.state.user.profile.user.display_name +
          " (" +
          this.$store.state.user.profile.data.email +
          ")"
        );
      }
    },
  },
  beforeDestroy() {
    if (this.pusherClient != null) {
      this.pusherClient.unsubscribe(this.pusherChannelName)
    }
  },
  mounted() {
    if (localStorage.getItem("user-profile")) {
      this.$store.state.user.profile = JSON.parse(
        localStorage.getItem("user-profile")
      );

      this.me = JSON.parse(localStorage.getItem("user-profile"));

      this.pusherClient = new Pusher(config.VUE_APP_PUSHER_KEY, { cluster: config.VUE_APP_PUSHER_CLUSTER })
      this.pusherChannelName = `channel-${this.$store.state.user.profile.data.user_id}`;
      this.pusherClient.subscribe(this.pusherChannelName)
      this.pusherClient.bind('profile-reload', data => {
        axios
          .get(config.VUE_APP_ENV_ASSISTANT_URL + "/me/profile")
          .then((resp) => {
            localStorage.setItem('user-profile', JSON.stringify(resp.data))
            this.$store.state.user.profile = resp.data;
          })
          .catch((err) => {
            if (err.message.includes("401")) {
              this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$router.push("/login");
              });
            } else {
              console.log("err", err);
            }
          });
      })

      axios
        .get(config.VUE_APP_ENV_ASSISTANT_URL + "/me/profile")
        .then((resp) => {
          localStorage.setItem('user-profile', JSON.stringify(resp.data))
          this.$store.state.user.profile = resp.data;
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
              this.$router.push("/login");
            });
          } else {
            console.log("err", err);
          }
        });
    }
  },
};
</script>

<style>
.padded {
  padding-left: 1rem;
}
</style>
